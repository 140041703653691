import * as React from 'react';
import styled from 'styled-components';

interface IInfoTextBox {
  title: string;
  text: string;
}

export const InfoTextBox: React.FC<IInfoTextBox> = ({ title, text }) => {
  return (
    <StyledInfoTextBox>
      <h3>{title}</h3>
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </StyledInfoTextBox>
  );
};

const StyledInfoTextBox = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 50px;
    font-size: 28px;
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 25px;
      margin: 0 auto;
      width: 40px;
      height: 5px;
      background: ${({ theme }) => theme.colors.coral};
    }
  }
  p {
    padding: 0 4em 0 4em;
  }
`;

import * as React from 'react';
import styled from 'styled-components';

export const Grid: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <StyledGrid className={className}>{children}</StyledGrid>;
};

const StyledGrid = styled.div`
  display: block;
  width: 100%;

  .container {
    display: grid;
    justify-content: center;

    &.column {
      grid-template-columns: 1fr;
    }
    &.columns-2 {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }
    &.columns-3 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
    }
    &.columns-4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 20px;
    }
    &.center-columns {
      justify-content: center;
    }
  }

  .col {
    &.center {
      align-self: center;
    }
    &.center-content {
      justify-content: center;
    }
  }
`;

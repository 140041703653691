import * as React from 'react';
import styled from 'styled-components';

interface ILogoSmall {
  className: string;
  alt: string;
}

export const LogoSmall: React.FC<ILogoSmall> = ({ className, alt }) => {
  return (
    <StyledLogoWrapper className={className} alt={alt}>
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4746 20.4908H16.3818V20.5836V23.8352C16.3818 24.4471 16.4475 24.8934 16.6846 25.2542C16.8843 25.5583 17.1986 25.7909 17.6674 26.016H12.8249C13.1557 25.7935 13.3757 25.5455 13.5145 25.2253C13.6796 24.8448 13.7254 24.3722 13.7254 23.7427V15.1509C13.7254 14.5209 13.6762 14.0452 13.5095 13.6631C13.37 13.3433 13.1512 13.0958 12.8263 12.8776H19.0257C20.5538 12.8776 21.8143 13.2123 22.6902 13.8527C23.5624 14.4904 24.0616 15.4368 24.0616 16.6842C24.0616 17.9316 23.5624 18.878 22.6918 19.5157C21.8175 20.1561 20.5603 20.4908 19.0389 20.4908H16.4746ZM16.4878 13.1944H16.3951V13.2871V20.1077V20.2004H16.4878H18.1401C19.2577 20.2004 20.0853 19.8462 20.6321 19.2221C21.177 18.6 21.4316 17.7227 21.4316 16.6974C21.4316 15.6719 21.1736 14.7946 20.627 14.1727C20.0787 13.5487 19.2512 13.1944 18.1401 13.1944H16.4878ZM24.439 9.01039L24.3457 8.77141L24.2646 9.01478L23.5984 11.0134H23.1727L24.3005 7.62997H24.3159L25.1916 9.8579L25.2779 10.0775L25.3642 9.8579L26.2399 7.62997H26.2553L27.3831 11.0134H26.9441L26.2779 9.01478L26.1968 8.77141L26.1035 9.01039L25.2806 11.1191H25.2619L24.439 9.01039Z"
          fill="url(#paint100_linear)"
          stroke="url(#paint101_linear)"
          strokeWidth="0.185482"
        />
        <rect
          x="0.566038"
          y="0.566038"
          width="35.2979"
          height="35.2979"
          stroke="url(#paint102_linear)"
          strokeWidth="1.13208"
        />
        <defs>
          <linearGradient
            id="paint100_linear"
            x1="17.6538"
            y1="25.9381"
            x2="17.6538"
            y2="7.53723"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#834628" />
            <stop offset="0.34375" stopColor="#824528" />
            <stop offset="0.572917" stopColor="#D7A87C" />
            <stop offset="0.791667" stopColor="#9C603F" />
            <stop offset="1" stopColor="#9A5E3D" />
          </linearGradient>
          <linearGradient
            id="paint101_linear"
            x1="17.6538"
            y1="25.9381"
            x2="17.6538"
            y2="7.53723"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#834628" />
            <stop offset="0.34375" stopColor="#824528" />
            <stop offset="0.572917" stopColor="#D7A87C" />
            <stop offset="0.791667" stopColor="#9C603F" />
            <stop offset="1" stopColor="#9A5E3D" />
          </linearGradient>
          <linearGradient
            id="paint102_linear"
            x1="12.4077"
            y1="36.0953"
            x2="12.4077"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#834628" />
            <stop offset="0.34375" stopColor="#824528" />
            <stop offset="0.572917" stopColor="#D7A87C" />
            <stop offset="0.791667" stopColor="#9C603F" />
            <stop offset="1" stopColor="#9A5E3D" />
          </linearGradient>
        </defs>
      </svg>
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

import { gql } from '@apollo/client';

export const VALUATION_BROKER_QUERY = gql`
  query BrokerValuation($input: BrokerInput) {
    getBroker(input: $input) {
      id
      name
      email
      cellphone
    }
  }
`;

export const ZIP_TO_OFFICE_QUERY = gql`
  query Search($input: SearchInput) {
    search(input: $input) {
      result {
        offices {
          list {
            urltag
          }
        }
      }
    }
  }
`;

export const SOLD_PROPERTY_QUERY = gql`
  query GetSoldProperty($input: PropertyInput) {
    property: getProperty(input: $input) {
      id
      address
      location {
        searchLocation
        county
      }
      zip
      place
      office {
        info {
          name
          urltag
          zip
        }
      }
      brokers {
        list {
          responsible
          name
        }
      }
      images {
        first {
          url
        }
      }
    }
  }
`;

export const HANS_CHRISTIAN_PROPERTY_QUERY = gql`
  query GetProperty($input: PropertiesInput) {
    property: getProperties(input: $input) {
      total
      list {
        id
        address
        place
        type
        price {
          info {
            price
            priceTo
          }
        }
        images {
          list {
            url
          }
        }
        brokers {
          list {
            name
            responsible
          }
        }
      }
    }
  }
`;

export const REGISTER_INTERESTED_PROPERTY_QUERY = gql`
  query GetRegisterInterestProperty($input: PropertyInput) {
    property: getProperty(input: $input) {
      id
      wmId
      address
      hovedoppdrag
      price {
        info {
          price
          priceTo
        }
      }
      matrikkel {
        info {
          unit
        }
      }
      brokers {
        list {
          name
          title
          cellphone
          email
          path
          office {
            info {
              name
            }
          }
          image {
            full
          }
        }
      }
    }
  }
`;

export const SOLDPRICE_PROPERTY_QUERY = gql`
  query SoldPriceProperty($input: PropertyInput) {
    property: getProperty(input: $input) {
      id
      sold
      soldDate
      brokers {
        list {
          name
          title
          cellphone
          email
          path
          office {
            info {
              name
            }
          }
          image {
            full
          }
        }
      }
    }
  }
`;

export const OFFICE_QUERY = gql`
  query Office($input: OfficeInput) {
    office: getOffice(input: $input) {
      id
      name
      address
      postalAddress
      county
      place
      zip
      phone
      email
      text
      news {
        hasNews
      }
      seo {
        title
        description
        image
      }
      hasReferences
      brokers {
        list {
          name
          title
          email
          cellphone
          path
          image {
            full
          }
        }
      }
      naering
    }
  }
`;

export const BROKER_QUERY = gql`
  query Broker($input: BrokerInput) {
    broker: getBroker(input: $input) {
      id
      name
      title
      email
      cellphone
      path
      helper
      properties {
        show
      }
      offices {
        list {
          title
          name
          urltag
          groupTypes
        }
      }
      image {
        full
      }
      groupTypes {
        data {
          groups
          key
        }
      }
    }
  }
`;

export const PROPERTY_LIST_QUERY = gql`
  query Properties($input: PropertiesInput) {
    getProperties(input: $input) {
      total
      list {
        id
        address
        place
        type
        assignmentType
        sold
        soldDate
        checklistpoints {
          info {
            pmExclusive
            teaser
          }
        }
        freetext {
          list {
            finnLocalArea
          }
        }
        office {
          info {
            urltag
          }
        }
        images {
          first {
            url
          }
          list {
            url
          }
        }
        location {
          municipality
          municipalityArea
          county
        }
        price {
          info {
            price
            priceTo
          }
        }
        area {
          prom
          promTo
          bra
          braTo
        }
        units {
          sold
          total
          available
        }
      }
    }
  }
`;

export const PROPERTY_SLIDESHOW_QUERY = gql`
  query PropertySlideshow($input: PropertyInput) {
    getProperty(input: $input) {
      images {
        list {
          url
          text
        }
      }
    }
  }
`;

export const PROPERTY_DOCUMENTS_QUERY = gql`
  query PropertyDocuments($input: PropertyInput) {
    getProperty(input: $input) {
      documents {
        list {
          id
          name
          url
        }
      }
    }
  }
`;

export const PROPERTY_TYPES_QUERY = gql`
  query PropertyTypes($input: SearchInput) {
    search(input: $input) {
      result {
        properties {
          aggregations {
            propertyTypes {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const OFFICE_VALUATION_QUERY = gql`
  query OfficeValuation($input: OfficeInput) {
    getOffice(input: $input) {
      text: valuationText
    }
  }
`;

export const BROKER_OFFICE_SEARCH_QUERY = gql`
  query BrokerOfficeSearch($input: SearchInput) {
    search(input: $input) {
      result {
        landingpages {
          total
          list
        }
        offices {
          total
          list {
            name
            municipality
            urltag
          }
        }
        brokers {
          total
          list {
            name
            email
            phone
            cellphone
            path
            image {
              full
            }
            office {
              name
              title
            }
            active
          }
        }
      }
    }
  }
`;

export const BOLIGSOK_BY_HASH_QUERY = gql`
  query boligsokByHash($hash: String) {
    boligsokByHash(hash: $hash) {
      propertytype
      pricefrom
      priceto
      sizefrom
      sizeto
      bedroomsfrom
      geo {
        lat
        lng
        radius
      }
      active
    }
  }
`;

export const SAMTYKKER_BOLIGBYTTE_QUERY = gql`
  query GetAvailableSamtykkerByIds($ids: [Int]) {
    getAvailableSamtykkerByIds(ids: $ids) {
      id
      samtykketekst
    }
  }
`;

export const STILLINGER_QUERY = gql`
  query GetPositions($input: GetPositionsInput!) {
    getPositions(input: $input) {
      total
      list {
        id
        title
        description
        slug
        office {
          name
          urltag
        }
      }
    }
  }
`;

export const STILLING_QUERY = gql`
  query GetPosition($input: GetPositionInput!) {
    getPosition(input: $input) {
      id
      title
      info
      ingress
      description
      content
      slug
      office {
        name
        urltag
        lat
        lon
      }
    }
  }
`;

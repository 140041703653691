import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../../../ui/icons/Close';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Modal } from '../../../ui/modal/Modal';
import { Footer } from '../../../layout/Footer';
import { LogoSmall } from '../../../ui/logo/header/LogoSmall';

type BoligbytteModalType =
  | 'visningsvask'
  | 'flyttevask'
  | 'flyttehjelp'
  | 'mellomlagring'
  | 'flytteforsikring';

interface IBoligbytteInfoModalContext {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  modalType: BoligbytteModalType | null;
  setModalType: (value: BoligbytteModalType | null) => void;
  setModalTypeAndShow: (value: BoligbytteModalType | null) => void;
}

export const BoligbytteInfoModalContext =
  React.createContext<IBoligbytteInfoModalContext>({
    showModal: false,
    setShowModal: () => {},
    modalType: null,
    setModalType: () => {},
    setModalTypeAndShow: () => {}
  });

export const BoligbytteInfoModalContextProvider: React.FC = ({ children }) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [type, setType] = React.useState<BoligbytteModalType | null>(null);

  const handleSetTypeAndShow = (type: BoligbytteModalType | null) => {
    setType(type);
    setShow(true);
  };

  return (
    <BoligbytteInfoModalContext.Provider
      value={{
        showModal: show,
        setShowModal: setShow,
        modalType: type,
        setModalType: setType,
        setModalTypeAndShow: handleSetTypeAndShow
      }}
    >
      {children}
    </BoligbytteInfoModalContext.Provider>
  );
};

export const BoligbytteInfoModal: React.FC = React.memo(() => {
  const { showModal, setShowModal, modalType } = React.useContext(
    BoligbytteInfoModalContext
  );
  return (
    <Modal
      id="portals"
      inline={true}
      noBodyScroll={true}
      showModal={showModal}
      overlay={false}
      showCloseButton={true}
      onClickClose={() => setShowModal(false)}
      onClickOutside={() => setShowModal(false)}
    >
      <ModalHeader>
        <LogoSmall className="logo" />
        <CloseIcon
          className="modal-close"
          onClick={() => setShowModal(false)}
        />
      </ModalHeader>
      {modalType === 'visningsvask' ? (
        <ModalContent
          title="Visningsvask"
          text={`<p>Visningsvask er en grundig rengjøring av eiendom i forkant av visning. Denne skal bidra til at eiendommen fremstår på best mulig måte når du får potensielle kjøpere inn døren. 
          PrivatMegleren Boligbytte kan hjelpe deg med dette, og våre kvalitetssikrede samarbeidspartnere sørger for at alle rom er ordentlig rengjort..</p>
          <p>Visningsvask utføres normalt i møblerte eiendommer og inkluderer alle vaskbare overflater som f eks dører, vinduer, gulv, benkeplater, speil, osv. Tak og vegger vaskes ikke i forbindelse med visningsvask.</p> 
          <p>Skap og hvitevarer rengjøres utvendig, og vi kan bistå med innvendig rengjøring ved forespørsel (krever tømming av skap før rengjøring).</p>
          <p>Våre samarbeidspartnere stiller med alt nødvendig utstyr, og står for nødvendige og egnede kjemikalier og utstyr.</p>
          <p>Alt arbeid utføres ihht bransjestandarder, og alle samarbeidspartnere er godkjente renholdsbedrifter (Arbeidstilsynet) som kun benytter kvalifisert personell for å utføre jobben.</p>
          <p>Megler eller stylist kan ofte ønske at du fjerner deler av møbler og/eller inventar før foto og visning, for å fremheve eiendommens kvaliteter og detaljer. Dersom det viser seg at det er behov for å fjerne enkelte møbler o.l. i forkant av visning og/eller i forbindelse med styling av eiendom, kan PrivatMegleren Boligbytte også være behjelpelig med dette. Det vil si at vi sørger for å hente det som skal fjernes, transporterer og plasserer dette på et trygt og sikkert mellomlager, og leverer det tilbake når visningen er ferdig (vi kan evt. også oppbevare dette på mellomlager frem til du ønsker det fraktet og levert til ny adresse).</p>`}
          image="https://cdn.reeltime.no/pm_assets/boligbytte/tjenester/visningsvask.jpg"
        />
      ) : null}
      {modalType === 'flyttevask' ? (
        <ModalContent
          title="Flyttevask"
          text={`<p>En flyttevask er en grundig rengjøring av eiendom i forkant av overtakelse, og utføres normalt i en tom eiendom.</p>
          <p>En flyttevask er ofte mer omfattende enn mange tror, og vi anbefaler på det sterkeste å vurdere et profesjonelt og seriøst renholdsbyrå, fremfor å ta flyttevasken selv.</p>
          <p>PrivatMegleren Boligbytte kan hjelpe deg med dette, og våre kvalitetssikrede samarbeidspartnere sørger for at det både er rent på alle flater og i alle rom, og at det lukter rent og godt.</p> 
          <p>Flyttevasken leveres med 24-timers overtagelsesgaranti. Leverandøren står for alle kjemikalier og alt utstyr.</p>
          <p>PrivatMegleren Boligbytte bidrar også til optimal koordinering mellom flyttebyråer og vaskebyråer. Det vil si at når flyttebyrået har tømt eiendommen, står vaskebyrået klare til å begynne. På denne måten går hele flytteprosessen både raskere og smidigere, og du slipper å bruke tid på organisering.
          Tjenesten leveres ihht. bransjestandarder og  innebærer rengjøring av alle vaskbare flater som f.eks. dører, vinduer, gulv, tak og vegger.</p>
          <p>Skap rengjøres både ut- og innvendig, mens hvitevarer rengjøres utvendig (innvendig mot tillegg).
          Tak og vegger tørrmoppes.</p>
          <p>Leverandøren som vil utføre oppdraget er "Godkjent Renholdsbedrift" (Arbeidstilsynet).</p>
          <p>Alle virksomheter som tilbyr renholdstjenester skal være godkjent av Arbeidstilsynet, og det er ulovlig å selge renholdstjenester uten slik offentlig godkjenning.</p>
          <p>Overtagelsesgaranti innebærer at du som kunde kan klage på utført arbeid inntil 24 timer etter gjennomført overtagelse. 24 timers garanti innebærer at leverandøren kommer raskt tilbake for å utbedre det kjøper eventuelt ikke har godkjent, og overtagelse og videre prosess kan gjennomføres uten videre forsinkelser.</p>`}
          image="https://cdn.reeltime.no/pm_assets/boligbytte/tjenester/flyttevask.jpg"
        />
      ) : null}
      {modalType === 'flyttehjelp' ? (
        <ModalContent
          title="Flyttehjelp"
          text={`<p>Det å tømme en eiendom for alt innhold kan være mer omfattende og krevende enn man tror, og vi ser at stadig flere velger å sette bort denne jobben til profesjonelle aktører. 
          </p><p>Det skal gjøres mange tunge løft, og alt som skal flyttes må pakkes og sikres på forsvarlig måte før transport.
          PrivatMegleren Boligbytte hjelper deg med dette.</p>
          <p>Alle oppdrag settes opp med en egen teamleder og tilstrekkelig bemanning slik at jobben kan utføres så raskt og effektivt som mulig.
          Når flyttedagen nærmer seg, sender vi ut en påminnelse som bekrefter avtalt dato og tid.</p>
          <p>Teamleder har alltid en gjennomgang med kunde i forkant av oppdragets oppstart, slik at alle detaljer er avklart før vi går i gang.
          </p><p>PrivatMegleren Boligbytte kan også koordinere flyttebyrå og vaskebyrå, slik at vaskebyrået går i gang straks eiendommen eller deler av eiendommen er klar til rengjøring - raskt, effektivt og enkelt.
          </p><p>Vi benytter kun kvalitetssikrede og seriøse leverandører med gode referanser og tilbakemeldinger.         
          <p>Flytteoppdraget faktureres per time og bemanning beregnes ut i fra oppdragets omfang.</p>
          <p>Pris og bemanning beregnes med utgangspunkt i at alt flyttegods er ferdig nedpakket (vi bistår også med nedpakking dersom dette bestilles og spesifiseres på forhånd, og evt. timer medgått til dette avregnes til gjeldende timepris i henhold til tilbud).</p>
          <p>Leverandøren medbringer nødvendig utstyr og materiell slik at alt flyttegods blir håndtert skånsomt og forsvarlig. Dette inkluderer også materiell som beskytter og sikrer flyttegodset under transport.</p>
          <p>Vi kan bistå med avhenting og bortkjøring av avfall og dette faktureres til gjeldende timepris for oppdraget. Det tilkommer evt. en avfallsavgift på kr 2.600,- per påbegynte 1.000 kg. Spesialavfall som for eksempel maling, lakk, lim o.l. har egne satser på de ulike avfallsstasjonene og blir etterfaktuert etter gjeldende satser.</p>
          <p>OBS! Levering av spesialavfall/ farlig avfall er gratis for privatpersoner, men ikke for bedrifter (som for eksempel flyttebyråer og håndverkere).</p>
          <p>Norsk Gjenvinning og stadig flere avfallsstasjoner har for øvrig innført forbud mot levering av avfall i sorte/fargede (ikke transparente) søppelsekker</p>`}
          image="https://cdn.reeltime.no/pm_assets/boligbytte/tjenester/flyttehjelp.jpg"
        />
      ) : null}
      {modalType === 'mellomlagring' ? (
        <ModalContent
          title="Mellomlagring"
          text={`<p>Mange har behov for mellomlagring, og vi kan hjelpe deg med dette enten du skal fjerne noen møbler i forbindelse med visning, eller om du må lagre flyttegods i påvente av innflytting til ny eiendom.</p><p>Vi avtaler når og hvor mye som skal mellomlagres, henter flyttegodset, og transporterer og lagrer dette på en trygg og sikker måte. Du gir oss bare beskjed når og hvor du vil at vi skal levere dette igjen.</p>
          <p>Prisen på mellomlagring kalkuleres i forhold til volum og ønsket lagringsperiode, og dette vil bli spesifisert i et  skriftlig tilbud på tjenesten. </p>`}
          image="https://cdn.reeltime.no/pm_assets/boligbytte/tjenester/mellomlagring.jpg"
        />
      ) : null}
      {modalType === 'flytteforsikring' ? (
        <ModalContent
          title="Kostnadsfri flytteforsikring*"
          text={`<p>Mange tror at innbo-, eiendom- og/eller reiseforsikringen dekker eventuelle skader som oppstår i forbindelse med flytting. Det er ikke tilfelle. De aller fleste bør tegne en egen flytteforsikring uavhengig av om de skal flytte selv eller benytte flyttebyrå.</p>
          <p>Flytteforsikring kan bestilles/betales hos flere forsikringsselskaper, men her får de den gratis.</p>
          <p>Alle seriøse flyttebyråer (og de vi benytter) er forsikret iht. til bransjestandarder, men det er ikke nødvendigvis tilstrekkelig.</p>
          <p>Ofte lar man et flyttebyrå ta "de tunge tingene", mens man flytter noe selv også.</p>   
          <p>Vi opplever også tilfeller hvor kunden og flyttebyrået "skylder på hverandre" hvis noe er skadet, og her vil en egen flytteforsikring kunne bidra til at alt løses skånsomt for begge parter. </p>`}
          image="https://cdn.reeltime.no/pm_assets/boligbytte/tjenester/flytteforsikring.jpg"
        />
      ) : null}
    </Modal>
  );
});

const ModalContent: React.FC<{
  title: string;
  text: string;
  image: string;
}> = ({ title, text, image }) => {
  return (
    <StyledModalContent>
      <div className="container">
        <Heading tag="h2" center={true}>
          {title}
        </Heading>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <img src={image} alt="bilde-tjeneste" />
      </div>
      <StyledModalBackground />
    </StyledModalContent>
  );
};

const StyledModalBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://cdn.reeltime.no/pm_assets/boligbytte/Bg_topp_BB.jpg')
    no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: -1;
`;

const StyledModalContent = styled.div`
  position: relative;
  padding: 100px 0 100px 0;
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;

  .container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 30px;
  }

  p {
    color: white;
  }

  img {
    margin-top: 20px;
    width: 100%;
  }
`;

const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 4.51rem;
  padding: 0 30px;
  z-index: 1001;

  .modal-close {
    position: fixed;
    top: 25px;
    right: 25px;
    z-index: 10000;

    &:hover {
      cursor: pointer;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
`;

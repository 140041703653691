import * as React from 'react';
import { useLocation } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import { animated } from 'react-spring';

import { Section } from '../layout/Section';
import { Container } from '../layout/Container';
import { Heading } from '../ui/text/Heading';
import { Paragraph } from '../ui/text/Paragraph';

import { useFadeTransition } from '../../utils/hooks/useFadeTransition';
import { SiteContext } from '../../utils/context/SiteContext';
import { Seo } from '../../utils/seo/Index';
import { VideoPlayer } from '../ui/video-player/Index';
import { Modal } from '../ui/modal/Modal';
import { Form, FormRow } from '../ui/form/Form';
import { useForm } from '../../utils/hooks/useForm';
import { Input } from '../ui/form/Input';
import { ButtonGroup } from '../ui/button/ButtonGroup';
import { Button } from '../ui/button/Button';
import { ButtonLink } from '../ui/link/ButtonLink';
import { CalcContext, Flyttecalc } from './boligbytte/Flyttecalc';

import { Grid } from '../ui/grid/Grid';

import { ILeadInput, ILeadResponse } from '../../__types__/global';

import { useMutation, useQuery } from '@apollo/client';

import { SAMTYKKER_BOLIGBYTTE_QUERY } from '../../../client/__graphql__/queries';
import {
  BOLIGSOK_REGISTRER_SAMTYKKER_MUTATION,
  createLeadMutation
} from '../../../client/__graphql__/mutations';

import { FormError } from '../ui/form/FormError';
import { useFormTransition } from '../../utils/hooks/useFormTransition';
import { Checkbox } from '../ui/form/Checkbox';
import { AdvertisingPageSection } from '../ui/advertising/AdvertisingPageSection';
import { Hero } from '../ui/hero/Index';
import { InfoTextBox } from '../ui/text/InfoTextBox';
import { Card, CardVideo } from '../ui/cards/Card';
import {
  BoligbytteInfoModal,
  BoligbytteInfoModalContext,
  BoligbytteInfoModalContextProvider
} from './boligbytte/modal/Info';
import { DiggerType, useDiggerId } from '../../utils/hooks/useDiggerId';
import { Logo } from '../ui/logo/office/Index';

interface ISamtykke {
  id: number;
  samtykketekst: string;
}

interface ISamtykkeState extends ISamtykke {
  id: number;
  samtykketekst: string;
  checked: boolean | null;
}

interface ISamtykkeBoligbytte {
  getAvailableSamtykkerByIds: Array<ISamtykke>;
}

interface IRegistrerSamtykke {
  input: {
    navn: string;
    epost: string;
    telefon: string;
    tjeneste: number;
    samtykker: Array<number>;
  };
}

const Index: React.FC = () => {
  const location = useLocation();

  const infoScrollRef = React.useRef(null);
  const contactRef = React.useRef(null);
  const calcRef = React.useRef(null);

  const [state, setState] = React.useState({
    common: {
      size: ''
    },
    visningsvask: {
      open: false,
      selected: false,
      estimatedPrice: 0
    },
    flyttevask: {
      open: false,
      selected: false,
      bathrooms: '',
      takeover: '',
      wanteddate: '',
      doublewindows: '',
      wallsandroof: '',
      cleanappliances: '',
      numberOfAppliances: '1',
      terrace: '',
      numberOfTerraces: '1',
      estimatedPrice: 0
    },
    flyttehjelp: {
      open: false,
      selected: false,
      wanteddate: '',
      relevantinfo: '',
      calculatedDistance: 10,
      'address-from': null,
      'address-to': null,
      'etasje-from': 1,
      'etasje-to': 1,
      oppdragsnummer: '',
      estimatedPrice: 0
    },
    flytteforsikring: {
      open: false,
      selected: false,
      estimatedPrice: 0
    },
    mellomlagring: {
      open: false,
      selected: false,
      estimatedPrice: 0
    },
    total: {
      estimatedPrice: 0
    },
    calcData: {}
  });

  const { routeConfig } = React.useContext(SiteContext);
  const { setModalTypeAndShow } = React.useContext(BoligbytteInfoModalContext);

  const { submitWithDigger } = useDiggerId();

  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);

  const [contact, setContact] = React.useState<ISamtykkeState | null>(null);
  const [alreadyCustomer, setAlreadyCustomer] =
    React.useState<ISamtykkeState | null>(null);
  const [notCustomer, setNotCustomer] = React.useState<ISamtykkeState | null>(
    null
  );

  const toggleContact = (value?: boolean) => {
    if (!contact) {
      throw new Error('samtykke om kontakt er ikke hentet');
    }
    if (typeof value === 'boolean') {
      setContact({ ...contact, checked: value });
    } else {
      setContact({ ...contact, checked: !contact.checked });
    }
  };

  const toggleAlreadyCustomer = (value?: boolean | null) => {
    if (!alreadyCustomer) {
      throw new Error('samtykke om kontakt er ikke hentet');
    }
    if (typeof value === 'boolean' || value === null) {
      setAlreadyCustomer({ ...alreadyCustomer, checked: value });
    } else {
      setAlreadyCustomer({
        ...alreadyCustomer,
        checked: !alreadyCustomer.checked
      });
    }
  };

  const [boligsalg, setBoligsalg] = React.useState(false);

  React.useEffect(() => {
    if (contact?.checked && alreadyCustomer?.checked !== null) {
      toggleAlreadyCustomer(null);
    }
  }, [contact?.checked]);

  const [formHeight, setFormHeight] = React.useState(null);
  const formRef = React.useCallback((node) => {
    if (node !== null) {
      let height = node.getBoundingClientRect().height;
      setFormHeight(height);
    }
  }, []);

  const [registrerSamtykker] = useMutation<any, IRegistrerSamtykke>(
    BOLIGSOK_REGISTRER_SAMTYKKER_MUTATION
  );

  useQuery<ISamtykkeBoligbytte>(SAMTYKKER_BOLIGBYTTE_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getAvailableSamtykkerByIds) {
        let contactSamtykke = data.getAvailableSamtykkerByIds.filter(
          (item) => item.id === 2
        )[0];
        setContact({ ...contactSamtykke, checked: false });

        let notCustomer = data.getAvailableSamtykkerByIds.filter(
          (item) => item.id === 1
        )[0];
        setNotCustomer({ ...notCustomer, checked: false });

        let alreadySamtykke = data.getAvailableSamtykkerByIds.filter(
          (item) => item.id === 3
        )[0];
        setAlreadyCustomer({ ...alreadySamtykke, checked: null });
      }
    },
    variables: {
      ids: [1, 2, 3]
    }
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
        success
      }`
    }
  });

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
        data {
          id
          address
          zip
          phone
          email
        }
      }`
    }
  });

  const [valuation] = useMutation<ILeadResponse, ILeadInput>(
    VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.valuation?.success) {
        }
      }
    }
  );

  const [mail, { error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        setLoading(false);
        if (lead && lead.mail && lead.mail.success) {
          setPosted(true);
        }
      }
    }
  );

  /*
    Extra consents checks
  */

  const [contactNotChecked, setContactNotChecked] =
    React.useState<boolean>(false);
  const [alreadyCustomerNotChecked, setAlreadyCustomerNotChecked] =
    React.useState<boolean>(false);

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ola@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    onSubmit: (hasErrors) => {
      const errors = [];
      if (contact?.checked === false) {
        setContactNotChecked(true);
        errors.push('error');
      } else {
        setContactNotChecked(false);
      }
      if (alreadyCustomer?.checked === null) {
        setAlreadyCustomerNotChecked(true);
        errors.push('error');
      } else {
        setAlreadyCustomerNotChecked(false);
      }
      hasErrors(errors.length > 0);
    },
    submit: () => {
      const preparedFields: any = getFields(fields);

      setLoading(true);

      mail({
        variables: {
          input: {
            ...preparedFields,
            json: state,
            referer: `${location.pathname}${location.search}`,
            leadType: 'EPOST',
            mailType: 11
          }
        }
      });

      let samtykkeIds: Array<number> = [];
      if (contact?.checked) {
        samtykkeIds.push(contact.id);
      }
      if (alreadyCustomer?.checked === true) {
        samtykkeIds.push(alreadyCustomer.id);
      } else if (alreadyCustomer?.checked === false && notCustomer?.id) {
        samtykkeIds.push(notCustomer.id);
      }

      if (samtykkeIds.length) {
        registrerSamtykker({
          variables: {
            input: {
              navn: preparedFields.name,
              epost: preparedFields.email,
              telefon: preparedFields.phone,
              tjeneste: 1,
              samtykker: samtykkeIds
            }
          }
        });
      }

      if (boligsalg) {
        submitWithDigger(
          DiggerType.VERDIVURDERING,
          {
            zip: preparedFields.zip
          },
          () => {
            valuation({
              variables: {
                input: {
                  ...preparedFields,
                  referer: `${location.pathname}${location.search}`,
                  leadType: 'VERDIVURDERING',
                  pageType: 'GENERELL'
                }
              }
            });
          }
        );
      }
    }
  });

  const { fadeIn: formFadeIn, fadeOut: formFadeOut } = useFormTransition({
    posted
  });

  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  return (
    <>
      <FooterOverrideStyles />
      <Seo
        title="PrivatMegleren Boligbytte"
        description="PrivatMegleren Boligbytte hjelper deg med alt fra A til Å når du skal flytte til ny bolig"
        image="https://cdn.reeltime.no/pm_assets/boligbytte/Bg_topp_BB.jpg"
        imageTitle="/boligbytte-flytting-PrivatMegleren-hjelper-deg-enkelt-raskt-trygt-fra-gammel-eiendom-til-ny-eiendom."
        url="/boligbytte"
      />
      <Page style={{ ...fadeIn }}>
        <Hero
          className="content-height padding-top-50 padding-bottom-80"
          size="cover"
          position="bottom"
          imageUrl="https://cdn.reeltime.no/pm_assets/boligbytte/Bg_topp_BB.jpg"
          opacity={0.6}
          alt="bilde"
        >
          <HeroContent>
            <Logo
              name="Boligbytte"
              src="https://cdn.reeltime.no/pm_assets/logo/pm_versjon2.svg"
              alt="bilde av logo"
            />
            <Heading tag="h1" center={true}>
              Så enkelt skal det være å flytte
            </Heading>
            <Paragraph center={true} className="ingress">
              <span
                className="inline-clickable"
                onClick={() => {
                  if (infoScrollRef.current) {
                    const top = infoScrollRef.current.offsetTop - 120;
                    window.scroll({ top: top, behavior: 'smooth' });
                  }
                }}
              >
                PrivatMegleren Boligbytte
              </span>{' '}
              er en komplett tjeneste for hele flytteprosessen - fra gammel til
              ny eiendom. Sammen med våre håndplukkede samarbeidspartnere kan vi
              bistå med alt fra vask, styling og klargjøring av eiendommen før
              visning, til pakking, mellomlagring, utvask og flytting når
              eiendommen er solgt. Vi kan også håndtere formidling av andre
              avtaler slik som strøm, telecom og alarm.
            </Paragraph>
            <Paragraph center={true}>
              Vi hjelper deg gjennom hele prosessen. Så enkelt skal det være å
              flytte.
              {/* Vi hjelper deg gjennom hele prosessen. Så enkelt skal det nemlig
              være å flytte. */}
            </Paragraph>{' '}
            {/*     <ButtonGridGroup>
              <Button
                type="primary"
                colorTheme="gold"
                onClick={() => {
                  if (calcRef.current) {
                    const top = calcRef.current.offsetTop - 100;
                    window.scroll({ top: top, behavior: 'smooth' });
                  }
                }}
              >
                Estimer kostnad her
              </Button>

              <Button
                type="secondary"
                colorTheme="gold"
                onClick={() => {
                  if (contactRef.current) {
                    const top = contactRef.current.offsetTop - 100;
                    window.scroll({ top: top, behavior: 'smooth' });
                  }
                }}
              >
                Bli kontaktet
              </Button>
            </ButtonGridGroup> */}
          </HeroContent>
        </Hero>
        {/*   <Container ref={calcRef}>
          <Section style={{ padding: '2em 0 4em 0' }}>
            <ContentWrapper style={{ marginBottom: '40px' }}>
              <Heading tag="h2" center={true}>
                Estimer kostnad på tjenestene
              </Heading>
              <Paragraph center={true}>
                Under kan du estimere kostnadene på de ulike tjenestene.
              </Paragraph>
            </ContentWrapper>
            <ContentWrapper>
              <CalcContext.Provider value={{ state, setState }}>
                <Flyttecalc />
              </CalcContext.Provider>
            </ContentWrapper>
          </Section>
        </Container> */}
        <EmbedContainer style={{ marginTop: '4em' }}>
          <iframe
            className="VimeoOuterContainer"
            src="https://player.vimeo.com/video/799046455"
            /*  src="https://player.vimeo.com/video/799046455?amp;loop=0&background=1&autoplay=0&muted=1&controls=0" */
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </EmbedContainer>
        <FullSection>
          <Paragraph center={true}>
            Kontakt PrivatMegleren nær deg for mer informasjon
          </Paragraph>
          <ContactInfo className="margin-bottom">
            <a href="https://privatmegleren.no/kontorer">privatmegleren.no</a>
          </ContactInfo>
        </FullSection>
        {/*         <FullSection>
                    <Container>
            <Section className="form-section" ref={contactRef}>
              <Paragraph center={true}>
                Fyll ut skjemaet under hvis du ønsker at vi kontakter deg, eller
                du kan ringe eller maile oss.
              </Paragraph>
              <ContactInfo className="margin-bottom">
                <a href="tel:41361964">t. 413 61 964</a>
                <a href="mailto:boligbytte@privatmegleren.no">
                  boligbytte@privatmegleren.no
                </a>
              </ContactInfo>
              <Heading tag="h2" center={true}>
                Bli kontaktet
              </Heading>
              <FormWrapper style={formFadeOut}>
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  noValidate
                  style={{ padding: '30px 0' }}
                >
                  {error &&
                    error.graphQLErrors.map((error, index) => (
                      <FormError
                        key={`error_${index}`}
                        message={error.message}
                      />
                    ))}
                  {fields.map((item, index) => {
                    return (
                      <FormRow key={`field_${index}`}>
                        <Input
                          type={item?.type}
                          name={item?.name}
                          label={item?.label}
                          labelAfter={item?.labelAfter}
                          placeholder={item?.placeholder}
                          value={item?.value}
                          onChange={handleChange}
                          error={item?.error}
                        />
                      </FormRow>
                    );
                  })}
                  <ConsentGroup>
                    <Checkbox
                      label={contact?.samtykketekst}
                      labelPos="right"
                      checked={contact?.checked}
                      error={contactNotChecked && contact?.checked === false}
                      onClick={() => {
                        toggleContact();
                      }}
                    />
                  </ConsentGroup>
                  {contact?.checked ? (
                    <ConsentGroup style={{ paddingLeft: '20px' }}>
                      <ConsentGroupRow style={{ marginBottom: '20px' }}>
                        <Checkbox
                          label={alreadyCustomer?.samtykketekst}
                          labelPos="right"
                          checked={alreadyCustomer?.checked === true}
                          error={
                            alreadyCustomerNotChecked &&
                            alreadyCustomer?.checked === null
                          }
                          onClick={() => {
                            toggleAlreadyCustomer(true);
                          }}
                        />
                      </ConsentGroupRow>
                      <ConsentGroupRow>
                        <Checkbox
                          label="Nei, jeg er/har ikke vært kunde."
                          labelPos="right"
                          subText={notCustomer?.samtykketekst}
                          checked={alreadyCustomer?.checked === false}
                          error={
                            alreadyCustomerNotChecked &&
                            alreadyCustomer?.checked === null
                          }
                          onClick={() => {
                            toggleAlreadyCustomer(false);
                          }}
                        />
                      </ConsentGroupRow>
                    </ConsentGroup>
                  ) : null}
                  <ConsentGroup>
                    <ConsentGroupRow>
                      <Checkbox
                        label="Jeg anmoder PrivatMegleren om å kontakte meg på telefon, sms eller e-post i forbindelse med verdivurdering (eTakst) av nåværende eiendom og i forbindelse med kjøp og/eller salg av eiendom."
                        labelPos="right"
                        checked={boligsalg}
                        onClick={() => {
                          setBoligsalg(!boligsalg);
                        }}
                      />
                    </ConsentGroupRow>
                  </ConsentGroup>
                  <ButtonGroup>
                    <Button
                      type="primary"
                      colorTheme="gold"
                      disabled={loading}
                      loading={loading}
                    >
                      Kontakt meg
                    </Button>
                    <ButtonLink
                      as="a"
                      href={routeConfig.personvern.url}
                      className="privacy-link-mobile"
                    >
                      Personvernpolicy
                    </ButtonLink>
                  </ButtonGroup>
                  <InfoBox style={{ marginTop: '40px' }}>
                    Vi gjør oppmerksom på at meglerforetaket mottar godtgjørelse
                    direkte fra våre underleverandører for formidling av noen av
                    tjenestene.
                  </InfoBox>
                </Form>
              </FormWrapper>
              <FormWrapper style={{ ...formFadeIn }}>
                <FormSuccess style={{ height: `${formHeight}px` }}>
                  <Heading tag="h2" center={true}>
                    <span
                      style={{
                        color: '#e8c893',
                        fontSize: '48px',
                        fontWeight: 400
                      }}
                    >
                      Takk for din henvendelse.
                    </span>
                  </Heading>
                  <div style={{ maxWidth: '600px', lineHeight: '1.4' }}>
                    <p>
                      Når vi kontakter deg, ønsker vi å gå gjennom hvilke behov
                      du har og det du ønsker hjelp til. Vi vil i etterkant av
                      samtalen og sende et skriftlig tilbud på aktuelle
                      produkter og tjenester dersom det er av interesse.
                    </p>
                    <p>
                      Ønsker du for eksempel tilbud på flyttevask, mottar du et
                      tilbud på hva dette prises til, samt hva som er inkludert
                      i tjenesten. Dersom du ønsker å benytte deg av tilbudet,
                      booker vi en av våre samarbeidspartnere på ønsket
                      tidspunkt. Da hører du fra oss.
                    </p>
                    <p>
                      Med vennlig hilsen
                      <br />
                      PrivatMegleren Boligbytte
                    </p>
                  </div>
                </FormSuccess>
              </FormWrapper>
            </Section>
          </Container>
        </FullSection> */}
        {/*         <GridSection>
          <Heading tag="h2" center={true} style={{ marginBottom: '2em' }}>
            PrivatMegleren Boligbytte
          </Heading>
          <Grid className="card-grid">
            <div className="container center-columns ">
              <div className="col">
                                <Card>
                  <h2>Kostnadsfri flytteforsikring*</h2>
                  <img
                    alt="gjensidige-logo"
                    src="https://cdn.reeltime.no/pm_assets/boligbytte/Gjensidige_logo.jpg"
                  />
                  <p>
                    *Du får kostnadsfri flytteforsikring dersom du er kunde hos
                    oss og benytter deg av tjenesten PrivatMegleren Boligbytte.
                    Dersom du ønsker denne forsikringen, må den registreres hos
                    oss for at den skal gjelde.
                  </p>
                </Card>
              </div>
              <div className="col">
                <Card>
                  <h2>En komplett tjeneste</h2>
                  <CardVideo
                    src="https://cdn.reeltime.no/pm_assets/boligbytte/Videostill_uten_play.jpg"
                    url="https://player.vimeo.com/video/515287324?color=e8c893&title=0&byline=0&portrait=0&controls=1"
                  />
                  <p>
                    Med PrivatMegleren Boligbytte kan du bare lene deg tilbake
                    og nyte hele flytteprosessen. Vi tar oss av alt for deg, og
                    sørger for at tiden det tar å komme seg fra gammelt til nytt
                    kan benyttes til andre ting.
                  </p>
                </Card>
              </div>
            </div>
          </Grid>
        </GridSection> */}
        <GridSection className="transparent">
          <Grid className="text-grid">
            <div className="container columns-2">
              <div className="col">
                <InfoTextBox
                  title="Vask og styling"
                  text="Det er de små detaljene som utgjør den store forskjellen. Vi vasker, setter bort møbler og klargjør eiendommen din til både visning og overtakelse."
                />
              </div>
              <div className="col">
                <InfoTextBox
                  title="Pakking og flytting"
                  text="Vi kan rydde, pakke og 
                  flytte alle tingene dine ut av gammelt og inn i nytt. Vi hjelper også med lagring mens du er mellom to eiendommer."
                />
              </div>
              {/*               <div className="col">
                <InfoTextBox
                  title="Strøm og avtaler"
                  text="Du kan overlate overføring av strøm og andre avtaler til oss. Vi får også alarmer installert i din nye eiendom og flytter kundeforholdet for deg."
                />
              </div> */}
            </div>
            <div className="col">
              <InfoTextBox
                title="Flytteprosessen"
                text="Vi hjelper deg gjennom hele flytteprosessen. Så enkelt skal de være å flytte."
              />
            </div>
          </Grid>
        </GridSection>
        <AdvertisingPageSection
          logoWithManualLabel={false}
          logoUrl="https://cdn.reeltime.no/pm_assets/logo/pm_kjop.svg"
          readmoreLink={routeConfig.kjop.url}
          videoUrl="https://player.vimeo.com/video/515185604?autoplay=1"
        >
          <Heading tag="h2" center={true}>
            PrivatMegleren Boligbytte er en komplett tjeneste for hele
            flytteprosessen - fra gammel til ny eiendom.
          </Heading>
          <Paragraph
            center={true}
            style={{ maxWidth: '500px', margin: '0 auto' }}
          >
            Få hjelp og taktisk rådgivning på leting etter din neste eiendom.
            Med tjenesten KJØP er megler med deg i hvert trekk av
            eiendomskjøpet.
          </Paragraph>
        </AdvertisingPageSection>
        <Modal
          id="portals"
          noBodyScroll={true}
          showModal={showVideo}
          overlay={true}
          showCloseButton={true}
          onClickClose={() => setShowVideo(false)}
          onClickOutside={() => setShowVideo(false)}
        >
          <VideoPlayer
            type="vimeo"
            url="https://player.vimeo.com/video/477520551?color=e8c893&title=0&byline=0&portrait=0&controls=1"
          />
        </Modal>
        <BoligbytteInfoModal />
      </Page>
    </>
  );
};

export const BoligbytteLogo: React.FC<{ className?: string }> = (props) => {
  return (
    <StyledBoligbytteLogoWrapper className={props.className}>
      <StyledBoligbytteLogo src="https://cdn.reeltime.no/pm_assets/img/privatmeglerenboligbytte_logo.png" />
    </StyledBoligbytteLogoWrapper>
  );
};

const Boligbytte: React.FC = (props) => {
  return (
    <BoligbytteInfoModalContextProvider>
      <Index {...props} />
    </BoligbytteInfoModalContextProvider>
  );
};

export default Boligbytte;

const StyledBoligbytteLogoWrapper = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const StyledBoligbytteLogo = styled.img`
  position: relative;
  height: 160px;
  margin: 0 auto 10px auto;
`;

const Page = styled(animated.div)`
  width: 100%;
  padding-top: 72px;

  .boligbytte-heading {
    max-width: 80%;
  }
`;

const FormWrapper = styled(animated.div)``;

const FormSuccess = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
`;

const ConsentGroup = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 20px;
`;

const ConsentGroupRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const InfoBox = styled.div`
  width: 100%;
  padding: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.burgundy};
`;

const GridSection = styled.section`
  display: block;
  background: ${({ theme }) => theme.colors.burgundy};
  padding: 50px 30px 50px 30px;

  &.transparent {
    background: transparent;
  }

  &.black {
    background: rgb(0, 0, 0);
  }

  .container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }

  .text-grid {
    @media all and (max-width: 1000px) {
      .columns-3 {
        grid-template-columns: 1fr;
      }
      .columns-2 {
        grid-template-columns: 1fr;
      }

      .col {
        display: grid;
        justify-items: center;

        div {
          max-width: 470px;
        }
      }
    }
  }

  .card-grid {
    @media all and (max-width: 1000px) {
      .columns-2 {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
      }

      .col {
        display: grid;
        justify-items: center;

        div {
          max-width: 470px;
        }
      }
    }
  }
`;

const HeroContent = styled(animated.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 0 30px;

  h1 {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
  }

  h2 {
    width: 100%;
    word-break: break-word;
  }

  .inline-clickable {
    color: ${({ theme }) => theme.colors.primary};

    &:hover  {
      cursor: pointer;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 20px;

  &.margin-bottom {
    margin-bottom: 40px;
  }

  a {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FooterOverrideStyles = createGlobalStyle`
  .footer {
    border-top: none;
    margin-top: 0;
  }
  `;

const ButtonGridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 380px;

  @media all and (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;

const FullSection = styled.section`
  background: #170308;
  padding: 20px;
`;
const EmbedContainer = styled.div`
  position: relative;
  padding: 28.07% 0 0 0;
  .VimeoOuterContainer {
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 4em;
  }

  @media all and (max-width: 1200px) {
    padding: 73.07% 0 0 0;
    margin-top: 0px;
    .VimeoOuterContainer {
      padding-bottom: 4em;
    }
  }
`;
